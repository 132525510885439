import { NextSeo } from "next-seo";

const Kriterie404 = () => {
  return (
    <>
      <NextSeo title={"Sidan kan inte hittas"} />
      <h1>404: Sidan kan inte hittas</h1>
    </>
  );
};

export default Kriterie404;
